import { createRouter, createWebHistory } from 'vue-router'

import UserInfoStore from '@/auth/user-info-store'
import Contacts from './contacts'
import Maintenance from './maintenance'
import Account from './account'

import Me from '@/api/me'
import partyAPI from '@/api/party'

import {
  getCurrentUser,
  fetchUserAttributes,
  fetchMFAPreference,
  signInWithRedirect
} from 'aws-amplify/auth'

// import NotFound from '@/components/Pages/NotFound.vue'
import Redirect from '@/components/Pages/Redirect.vue'

import Login from '@/views/auth/login/index.vue'

import { useAuthStore } from '@/stores'

const LOGIN_RETURN_PATH = import.meta.env.VITE_APP_COGNITO_LOGIN_REDIRECT

let routes = [
  // AUTH -------------------------------------------------------------
  // {
  //   path: '/login2',
  //   beforeEnter: async () => {
  //     await signInWithRedirect()
  //   },
  //   component: Redirect
  // },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/password/change',
    name: 'change-password',
    component: () => import('@/views/auth/password/change-password/index.vue')
  },
  {
    path: '/password/forgot',
    name: 'forgot-password',
    component: () => import('@/views/auth/password/forgot-password/index.vue')
  },
  {
    path: '/password/confirm',
    name: 'confirm-forgot-password',
    component: () =>
      import('@/views/auth/password/confirm-forgot-password/index.vue')
  },
  {
    path: '/mfa',
    name: 'mfa-setup',
    component: () => import('@/views/auth/mfa/setup/index.vue')
  },
  {
    path: '/mfa/selection',
    name: 'mfa-selection',
    component: () => import('@/views/auth/mfa/selection/index.vue')
  },
  {
    path: '/mfa/verification',
    name: 'mfa-verification',
    component: () => import('@/views/auth/mfa/verification/index.vue')
  },
  {
    path: LOGIN_RETURN_PATH,
    beforeEnter: async (to, from, next) => {
      try {
        await getCurrentUser()

        return next('/')
      } catch {
        return next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    },
    component: Redirect // required for Vue 3 in order for LOGIN_RETURN_PATH path to be recognized
  },
  {
    path: '/logout',
    component: () => import('@/views/logout/index.vue')
  },

  // APP -------------------------------------------------------------

  {
    path: '/',
    component: () => import('@/views/welcome/index.vue')
  },

  // {
  //   path: '/bulk-sq',
  //   component: () => import('@/views/bulk-sq')
  // },
  {
    path: '/quotes',
    component: () => import('@/views/quotes/index.vue')
  },
  {
    path: '/quotes/new',
    component: () => import('@/views/quotes/view.vue')
  },
  {
    path: '/quotes2/new',
    name: 'create-quote',
    component: () => import('@/views/quotes/quote.vue')
  },
  {
    path: '/quotes/:id',
    name: 'view-quote',
    component: () => import('@/views/quotes/view.vue')
  },
  {
    path: '/quotes2/:id',
    component: () => import('@/views/quotes/quote.vue')
  },
  {
    path: '/orders',
    component: () => import('@/views/orders/index.vue')
  },
  {
    path: '/orders/:id',
    component: () => import('@/views/orders/view.vue')
  },

  {
    path: '/services',
    component: () => import('@/views/services/index.vue')
  },
  {
    path: '/services/:id',
    component: () => import('@/views/services/service.vue')
  },
  {
    path: '/services/:id/tests',
    name: 'tests',
    component: () => import('@/views/tests/index.vue')
  },
  {
    path: '/services/:id/tests/:testId',
    name: 'selected-test',
    component: () => import('@/views/tests/view.vue')
  },
  {
    path: '/tickets',
    component: () => import('@/views/tickets/index.vue')
  },

  {
    path: '/invoices',
    component: () => import('@/views/invoices/index.vue')
  },

  {
    path: '/support',
    component: () => import('@/views/support/index.vue'),
    children: [
      {
        path: ':serviceId',
        name: 'selected-service',
        component: () => import('@/components/Support/ServiceSection.vue'),
        children: [
          {
            path: 'ticket/:ticketId',
            name: 'selected-ticket',
            component: () =>
              import('@/components/Support/Tickets/TicketSection.vue')
          }
        ]
      }
    ]
  },

  {
    path: '/groups',
    component: () => import('@/views/permissions/index.vue')
  },
  {
    path: '/groups/:id',
    component: () => import('@/views/permissions/group.vue')
  },

  ...Contacts,
  ...Maintenance,
  ...Account
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'not-found',
  //   component: NotFound
  // }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

async function requireAuth(to, from, next) {
  const userStore = useAuthStore()
  try {
    await getCurrentUser()

    if (!userStore?.cognitoUser?.sub) {
      const attributes = await fetchUserAttributes()
      userStore.setCognitoUser(attributes)
    }

    if (!userStore?.user?.partyId) {
      const me = await Me.get()
      userStore.STORE(me.data)
    }

    if (!userStore?.accountManager?.id) {
      const sfManager = await Me.getSalesforceManager()
      userStore.STORE_ACCOUNT_MANAGER(sfManager)
    }

    if (!userStore?.party?.id) {
      const party = await partyAPI.get(userStore.userPartyId)
      userStore.STORE_PARTY(party)
    }

    if (!userStore?.isMfaFetched) {
      const preference = await fetchMFAPreference()
      userStore.setMFAPreference(preference)
      userStore.setIsMfaFetched(true)
    }

    next()
  } catch (error) {
    console.log({ ERROR: error })
    if (
      error?.name?.toLowerCase()?.includes('auth') ||
      error?.message?.toLowerCase()?.includes('auth')
    ) {
      localStorage.setItem('redirectAfterLogin', location.pathname)
      UserInfoStore.setLoggedIn(false)

      next({
        path: '/login',
        query: { redirect: to?.fullPath === '/' ? undefined : to.fullPath }
      })
      return
    }

    next()
  }
}

const openPaths = [
  '/login',
  '/password/change',
  '/password/forgot',
  '/password/confirm',
  '/mfa',
  '/mfa/selection',
  '/mfa/verification',
  '/logout',
  '/maintenance',
  LOGIN_RETURN_PATH
] // Any paths that do not require authentication should go here

router.beforeEach(async (to, from, next) => {
  // Check if the app is in maintenance mode
  const inMaintenance = !!parseInt(import.meta.env.VITE_APP_MAINTENANCE_MODE)
  const maintenancePage = to.name === 'maintenance'

  if (!maintenancePage && inMaintenance) {
    next({ name: 'maintenance' })
    return
  } else if (maintenancePage && !inMaintenance) {
    next('/')
    return
  }

  let isOpenPath = openPaths.indexOf(to.path) >= 0
  if (isOpenPath) {
    next()
    return
  } else {
    await requireAuth(to, from, next)
  }
})

export default router
