import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './quasar'
import api from '@/api/apiHelper'
import { initSentry } from './plugins/sentry'
import VueApexCharts from 'vue3-apexcharts'
import InstantSearch from 'vue-instantsearch/vue3/es'
import { Quasar, Notify, Meta, Dialog } from 'quasar'
import { Amplify } from 'aws-amplify'
import { initAuthListener } from '@/auth'

import GmapMap from '@/components/Gmaps/GmapMap.vue'
import GmapAutocomplete from '@/components/Gmaps/GmapAutocomplete.vue'

const REDIRECT_ROOT = location.origin

const CLIENT_ID = import.meta.env.VITE_APP_COGNITO_CLIENT_ID
const APP_DOMAIN = import.meta.env.VITE_APP_COGNITO_APP_DOMAIN
const USERPOOL_ID = import.meta.env.VITE_APP_COGNITO_USERPOOL_ID
const REDIRECT_URI =
  REDIRECT_ROOT + import.meta.env.VITE_APP_COGNITO_LOGIN_REDIRECT
const REDIRECT_URI_SIGNOUT =
  REDIRECT_ROOT + import.meta.env.VITE_APP_COGNITO_LOGOUT_REDIRECT

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: USERPOOL_ID,
      userPoolClientId: CLIENT_ID,
      loginWith: {
        oauth: {
          domain: APP_DOMAIN,
          scopes: [
            'aws.cognito.signin.user.admin',
            'https://swoop.com.au/wholesale'
          ],
          redirectSignIn: [REDIRECT_URI],
          redirectSignOut: [REDIRECT_URI_SIGNOUT],
          responseType: 'token'
        }
      }
    }
  }
})

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)

// only import after pinia has been initialized
import { useAppStore } from '@/stores'
const appStore = useAppStore()

app.use(router)

app.use(Quasar, {
  plugins: {
    Notify,
    Meta,
    Dialog
  }
})

// Registers component for app wide use
app
  .component('GmapMap', GmapMap)
  .component('GmapAutocomplete', GmapAutocomplete)

app.use(InstantSearch)

api.config({
  apiRoot: import.meta.env.VITE_APP_API_ROOT,
  onShowSpinner() {
    appStore.showSpinner()
  },
  onHideSpinner() {
    appStore.hideSpinner()
  }
})

app.use(VueApexCharts)

// initSentry(app)

initAuthListener()

app.mount('#app')
