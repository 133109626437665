import { defineStore } from 'pinia'
import { Notify } from 'quasar'

export const useAppStore = defineStore('app', {
  state: () => ({
    showSpinnerState: null, // If true, a modal app spinner will appear (see actions > showSpinner() )
    confirmData: null, // If set, a modal confirmation message box will appear. See actions > confirm() for details of how this object is set.
    width: 0, // When the viewport width changes it is stored here so that it can be used in template logic if necessary (set by App.vue > onResize() method)
    height: 0 // When the viewport height changes it is stored here so that it can be used in template logic if necessary (set by App.vue > onResize() method)
  }),
  getters: {
    isMobile: state => state.width <= 1024
  },
  actions: {
    updateSpinnerState(state) {
      this.showSpinner = state
    },
    showSpinner() {
      this.showSpinnerState = true
    },
    hideSpinner() {
      this.showSpinnerState = false
    },
    setHeight(height) {
      this.height = height
    },
    setWidth(width) {
      this.width = width
    },
    updateConfirmData() {
      this.confirmData = null
    },
    confirm({ text, detail, okText, cancelText, icon, confirm }) {
      /*
        Shows a confirmation dialog and returns a promise. Parameters:
  
        - text (string): Primary message text
        - detail (string): Optional secondary message text
        - okText (string): Optional text for the confirm button (defaults to "OK")
        - cancelText (string): Optional cancel button text (defaults to "Cancel"). If set to null or empty, no cancel button is displayed.
        - icon (string): Optional icon name (from Material Icons) to use for the main icon. Otherwise a question mark icon will be used if the confirmation has confirm & cancel buttons, and otherwise an info icon will be used.
      */
      return new Promise(resolve => {
        this.confirmData = {
          text,
          detail,
          icon,
          okText,
          cancelText,
          onConfirm() {
            confirm()
            resolve(true)
          },
          onCancel() {
            resolve(false)
          }
        }
      })
    },
    notify({ message, type, timeout, caption }) {
      /*
          Shows a quick notification (toast / snackbar etc).
  
            params <object>:
              message <string>: Notification message text
              type <string>: Optional display type. Either "positive", "negative", "info" or "warning".
              timeout <number>: Optional timeout (defaults to 5000)
        */
      if (message) {
        Notify.create({
          type: type || 'positive',
          message: message,
          caption: caption || null,
          timeout: timeout || 5000,
          ...(type === 'info' && { color: 'primary' })
        })
      }
    }
  }
})
