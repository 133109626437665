export const PRODUCT_TYPE = {
  HOME: 'Home Internet',
  BUSINESS: 'Business Internet'
}

export const TECH_TYPE = {
  FTTP: 'Fibre',
  FTTB: 'Fibre To The Building',
  FTTN: 'Fibre To The Node',
  FTTC: 'Fibre To The Curb',
  HFC: 'HFC',
  FW: 'Wireless'
}

const ACCESS_TYPE = {
  [TECH_TYPE.FTTP]: 'Fibre',
  [TECH_TYPE.FTTB]: 'FTTB',
  [TECH_TYPE.FTTN]: 'FTTN',
  [TECH_TYPE.FTTC]: 'FTTC',
  [TECH_TYPE.HFC]: 'HFC',
  [TECH_TYPE.FW]: 'Fixed Wireless'
}

export const PRODUCT = [
  {
    bandwidth: '12/1',
    download: 12,
    upload: 1,
    productType: [PRODUCT_TYPE.HOME],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC
    ]
  },
  {
    bandwidth: '25/5',
    download: 25,
    upload: 5,
    productType: [PRODUCT_TYPE.HOME],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC,
      TECH_TYPE.FW
    ]
  },
  {
    bandwidth: '25/10',
    download: 25,
    upload: 10,
    productType: [PRODUCT_TYPE.HOME],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC
    ]
  },

  {
    bandwidth: '50/20',
    download: 50,
    upload: 20,
    productType: [PRODUCT_TYPE.HOME, PRODUCT_TYPE.BUSINESS],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC
    ]
  },

  {
    bandwidth: '100/20',
    download: 100,
    upload: 20,
    productType: [PRODUCT_TYPE.HOME],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC
    ]
  },

  {
    bandwidth: '100/40',
    name: 'Home Fast',
    download: 100,
    upload: 40,
    productType: [PRODUCT_TYPE.HOME, PRODUCT_TYPE.BUSINESS],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC
    ]
  },

  {
    bandwidth: '250/25',
    name: 'Home Superfast',
    download: 250,
    upload: 25,
    productType: [PRODUCT_TYPE.HOME],
    techType: [TECH_TYPE.FTTP, TECH_TYPE.HFC]
  },

  {
    bandwidth: '250/100',
    download: 250,
    upload: 100,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTP]
  },

  {
    bandwidth: '500/200',
    download: 500,
    upload: 200,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTP]
  },

  {
    bandwidth: '1000/50',
    name: 'Home Ultrafast',
    download: 1000,
    upload: 50,
    productType: [PRODUCT_TYPE.HOME],
    techType: [TECH_TYPE.FTTP, TECH_TYPE.HFC]
  },

  {
    bandwidth: '1000/400',
    download: 1000,
    upload: 400,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTP]
  },

  {
    bandwidth: '25/5',
    download: 25,
    upload: 5,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FW]
  },

  {
    bandwidth: '100/20',
    download: 100,
    upload: 20,
    productType: [PRODUCT_TYPE.HOME, PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FW]
  }
]

export const PRODUCT_FC = [
  {
    bandwidth: '100/20',
    download: 100,
    upload: 20,
    productType: [PRODUCT_TYPE.HOME],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '100/40',
    download: 100,
    upload: 40,
    productType: [PRODUCT_TYPE.HOME],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '250/25',
    name: 'Home Superfast',
    download: 250,
    upload: 25,
    productType: [PRODUCT_TYPE.HOME],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '1000/50',
    name: 'Home Ultrafast',
    download: 1000,
    upload: 50,
    productType: [PRODUCT_TYPE.HOME],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '100/40',
    download: 100,
    upload: 40,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '250/100',
    download: 250,
    upload: 100,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '500/200',
    download: 500,
    upload: 200,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  },

  {
    bandwidth: '1000/400',
    download: 1000,
    upload: 400,
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [TECH_TYPE.FTTP, TECH_TYPE.FTTN, TECH_TYPE.FTTC]
  }
]

export const FW_SLA = [
  {
    name: 'n/a',
    value: undefined,
    productType: [PRODUCT_TYPE.HOME]
  },

  {
    name: 'Standard',
    value: 'Standard',
    productType: [PRODUCT_TYPE.BUSINESS]
  }
]

export const SLA = [
  {
    name: 'n/a',
    value: 'n/a',
    productType: [PRODUCT_TYPE.HOME],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC,
      TECH_TYPE.FW
    ],
    bandwidth: getHomeProducts().map(o => o.bandwidth)
  },

  {
    name: 'Best Effort',
    value: 'Best Efforts',
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC,
      TECH_TYPE.FW
    ],
    bandwidth: getBusinessProducts()
      .filter(product => product.download <= 100)
      .map(o => o.bandwidth),
    bandwidth_fc: getBusinessFCProducts()
      .filter(product => product.download < 250)
      .map(o => o.bandwidth)
  },

  {
    name: 'Enhanced 12',
    value: 'Enhanced 12',
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTB,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC
    ],
    bandwidth: getBusinessProducts()
      .filter(product => product.download <= 100)
      .map(o => o.bandwidth),
    bandwidth_fc: getBusinessFCProducts()
      .filter(product => product.download < 250)
      .map(o => o.bandwidth)
  },

  {
    name: 'Enhanced 12',
    value: 'Enhanced 12 Bundled',
    productType: [PRODUCT_TYPE.BUSINESS],
    techType: [
      TECH_TYPE.FTTP,
      TECH_TYPE.FTTC,
      TECH_TYPE.HFC,
      TECH_TYPE.FTTN,
      TECH_TYPE.FTTB
    ],
    bandwidth: getBusinessProducts()
      .filter(product => product.download >= 200)
      .map(o => o.bandwidth),
    bandwidth_fc: getBusinessFCProducts()
      .filter(product => product.download >= 250)
      .map(o => o.bandwidth)
  }
]

/**
 * Gets all business products
 * @returns {({download: number, bandwidth: string, upload: number, productType: string[], techType: (string)[]}|{download: number, bandwidth: string, upload: number, productType: (string)[], techType: (string)[]}|{download: number, bandwidth: string, upload: number, productType: string[], techType: (string)[]}|{download: number, bandwidth: string, upload: number, name: string, productType: (string)[], techType: (string)[]}|{download: number, bandwidth: string, upload: number, name: string, productType: string[], techType: (string)[]})[]}
 */
function getBusinessProducts() {
  return PRODUCT.filter(product =>
    product.productType.includes(PRODUCT_TYPE.BUSINESS)
  )
}

function getBusinessFCProducts() {
  return PRODUCT_FC.filter(product =>
    product.productType.includes(PRODUCT_TYPE.BUSINESS)
  )
}

function getHomeProducts() {
  return PRODUCT.filter(product =>
    product.productType.includes(PRODUCT_TYPE.HOME)
  )
}

/**
 * Gets all products allowed on a product type and nbn tech type.
 *
 * @param {string} productType Product type
 * @param {string} techType NBN tech type
 * @param {number} maxDownload Max download speed
 * @param {number} maxUpload Max upload speed
 * @returns {Object[]} Products allowed on given product type and tech type
 */
export function getProducts(
  productType,
  techType,
  maxDownload = null,
  maxUpload = null,
  isFibreConnect = false
) {
  let products = isFibreConnect ? PRODUCT_FC : PRODUCT

  products = products.filter(p => {
    return p.productType.includes(productType) && p.techType.includes(techType)
  })

  // filter by speed
  if (
    !isFibreConnect &&
    maxDownload &&
    maxUpload &&
    [TECH_TYPE.FTTP, TECH_TYPE.FTTN, TECH_TYPE.FTTC, TECH_TYPE.FTTB].includes(
      techType
    )
  ) {
    var maxIndex = products.findIndex(x => x.download >= maxDownload)

    // fix for 100/20 and 100/40 resi plans (allow 100/40 if estimated upload speed exceeds 100/20 plan)
    if (
      products.length > maxIndex + 1 &&
      products[maxIndex].download === products[maxIndex + 1].download &&
      products[maxIndex].upload < maxUpload
    ) {
      maxIndex += 1
    }

    products = products.splice(0, maxIndex + 1)
  }

  return products
}

/**
 * Gets all SLAs allowed on a product type.
 *
 * @param {string} productType Product type
 * @param {string} productCode Product Code
 * @param {string} productTechType Product Tech Type
 * @returns {Object[]} SLAs allowed on given product type
 */
export function getSlas(productType, productCode, productTechType) {
  if (productCode && productCode === 'BROADBAND_FIXED_WIRELESS') {
    return FW_SLA.filter(p => {
      return p.productType.includes(productType)
    })
  } else if (productCode && productCode === 'NBN_TC4') {
    return SLA.filter(p => {
      return (
        p.productType.includes(productType) &&
        p.techType.includes(productTechType)
      )
    })
  } else {
    return SLA.filter(p => {
      return p.productType.includes(productType)
    })
  }
}

/**
 * Converts an NBN provided primary access technology to what sq-service
 * endpoint expects.
 *
 * @param {string} primaryAccessTechnology NBN Primary access technology
 * @returns {string} Null on not found | NBN Technology type
 */
export function getAccessType(primaryAccessTechnology) {
  return ACCESS_TYPE[primaryAccessTechnology]
}

/**
 * Converts an access type (saved quote/order) to NBN primary access technology.
 *
 * @param {string} accessType Access type from saved quote/order
 * @returns {string} Null on not found | NBN Technology type
 */
export function getTechType(accessType) {
  var techType = null
  for (const key of Object.keys(ACCESS_TYPE)) {
    if (accessType === ACCESS_TYPE[key]) {
      techType = key
      break
    }
  }

  return techType
}
