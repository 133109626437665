import { defineStore } from 'pinia'
import { tc4OperatingHoursToString } from '@/utils/swoop'
import { getAccessType, TECH_TYPE } from '@/data/products'

export const useQuoteStore = defineStore('quote', {
  state: () => ({
    currentStep: 'installation_location',
    quoteOrderId: null,
    location: null,
    sfProducts: null,
    sfLocation: null,
    sqProducts: [],
    products: [],
    calculatedProductResponse: null,
    quoteName: null,
    reference: null,
    contacts: [],
    isFormDirty: false,
    currentQuote: null,
    terms: null,
    isCalculating: false,
    acceptedAll: false
  }),
  getters: {
    totalMRC: state => {
      return state.products
        ?.map(product => parseFloat(product?.pricing?.monthly) || 0)
        ?.reduce((acc, curr) => acc + curr, 0)
    },

    totalInstallFee: state => {
      return state.products
        ?.map(
          product =>
            parseFloat(product?.pricing?.setup) +
              parseFloat(product?.pricing?.build_cost) || 0
        )
        ?.reduce((acc, curr) => acc + curr, 0)
    },

    getProductPayload(state) {
      const newProducts = state.products.map((product, i) => {
        let newProduct = {
          ...product,
          service_type: product.productType === 'DIA' ? 'internet' : 'ethernet',
          sla: product.configuration?.SLA__c,
          request_date: new Date().toISOString(),
          circuit_id: '',
          notes: '',
          options_map: {
            ...product.options_map,
            productObject: product
          }
        }

        if (product.group === 'NBN TC4') {
          const isFC = product?.connectionType === 'fibre_connect'

          newProduct = {
            ...newProduct,
            access_type:
              product?.access_type === 'FTTP' || isFC
                ? 'Fibre'
                : product?.access_type,

            service_type: product?.productType,

            connectionType: isFC ? 'Fibre Connect' : product?.connectionType,

            additional_info: {
              ...newProduct.additional_info,
              is_nbn_fc: isFC
            }
          }

          if (product.additional_info?.operating_hours) {
            newProduct = {
              ...newProduct,
              additional_info: {
                ...newProduct.additional_info,
                operating_hours: tc4OperatingHoursToString(
                  product.additional_info?.operating_hours
                )
              }
            }
          }

          if (isFC) {
            newProduct = {
              ...newProduct,
              options_map: {
                ...newProduct.options_map,
                accessType: product?.productType,
                leadTime: product?.leadTime
              }
            }
          }
        }

        return newProduct
      })

      return newProducts
    },

    // payload when creating/saving a quote
    getQuotePayload(state) {
      const payload = {
        quote_name: state.quoteName,
        reference: state.reference || '',
        opportunity_reference: state.location?.address,
        sq_location_object: state.sfLocation,
        contacts: state.contacts,
        sq_products_object: state.sfProducts,
        products: this.getProductPayload
      }

      return payload
    },

    // payload when updating quote questionnaire
    getUpdateQuotePayload(state) {
      const newServices = state.currentQuote?.services?.map((service, i) => {
        let newService = service

        const product = state.products?.find(
          product => product.group === service.options_map?.productObject?.group
        )

        if (product) {
          newService = {
            ...newService,
            additional_info: product.additional_info,
            options_map: {
              ...service.options_map,
              ...product.options_map
            }
          }
        }

        if (i === 0) {
          newService = {
            ...newService,
            contacts: state.contacts
          }
        }

        return newService
      })

      return {
        ...state.currentQuote,
        services: newServices
      }
    }
  },
  actions: {
    setCurrentStep(step) {
      this.currentStep = step
    },
    setLocation(location) {
      this.location = location
    },
    setSFProducts(products) {
      this.sfProducts = products
    },
    setSFLocation(location) {
      this.sfLocation = location
    },
    setProducts(products) {
      this.products = products
    },
    setCalculatedProductResponse(response) {
      this.calculatedProductResponse = response
    },
    setQuoteOrderID(orderId) {
      this.quoteOrderId = orderId
    },
    setFormIsDirty(state) {
      this.isFormDirty = state
    },
    setCurrentQuote(quote) {
      this.currentQuote = quote
    },
    setTerms(terms) {
      this.terms = terms
    },
    setIsCalculating(state) {
      this.isCalculating = state
    },
    setAcceptedAll(state) {
      this.acceptedAll = state
    }
  }
})
